
import { defineComponent } from "vue";
import { platforms } from "@/store/pinia/Platforms";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-not-connected-platforms",
  props: {
    platforms: { type: Object },
    botId: { type: String },
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const platformsHolder = platforms();
    const { platformsList } = storeToRefs(platformsHolder);

    return {
      platformsList,
      translate,
    };
  },
});
