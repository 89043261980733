import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "symbol-group symbol-hover d-flex flex-nowrap text-nowrap" }
const _hoisted_3 = {
  key: 0,
  class: "symbol symbol-circle bg-light symbol-25px"
}
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.platforms.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: `/bot-connections/connect/${_ctx.botId}/${_ctx.platforms[0]}`,
          class: "opacity-25 opacity-100-hover text-nowrap btn btn-sm btn-outline btn-outline-dashed btn-active-light me-1 mb-1 d-flex align-items-center p-1"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.platformsList, (pl, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                  (_ctx.platforms.includes(i))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("img", {
                          src: pl.logo,
                          alt: pl.name
                        }, null, 8, _hoisted_4)
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.translate("connect")), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]))
    : _createCommentVNode("", true)
}