
import { defineComponent, onMounted, ref, watch } from "vue";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { statusColor } from "@/core/services/HelpersFun";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import JwtService from "@/core/services/JwtService";
import { useI18n } from "vue-i18n";
import { checkPackagePlatform } from "@/core/services/HelpersFun";
import platform from "@/components/Platform.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { platforms } from "@/store/pinia/Platforms";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";
import NotConnectedPlatform from "@/components/NotConnectedPlatform.vue";
import EditConnectionModal from "@/views/pages/SubscriptionConnections/component/EditConnectionModal.vue";
import BuyNew from "@/views/pages/buy/buy-new.vue";
import userInfo from "@/core/services/UserInfo";
import BuyHandler from "@/views/pages/buy/Buy/BuyHandler.vue";
import { Servers } from "@/store/pinia/Servers";

export default defineComponent({
  name: "kt-table-bot-list",
  props: {
    widgetClasses: String,
    user: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    EditConnectionModal,
    platform,
    NotConnectedPlatform,
    BuyNew,
    BuyHandler,
  },
  forceUpdate() {
    this.$forceUpdate();
  },
  setup(props) {
    const dialogVisible = ref(false);
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const userData = userInfo.getUser();

    const route = useRoute();
    const loading = ref(false);
    const botId = ref("");
    const forUser = ref();
    const expiration_type = ref("active");

    watch(
      () => props.user,
      (user) => {
        if (!user) {
          forUser.value = undefined;
          return;
        }

        user = JSON.parse(JSON.stringify(user));
        forUser.value = user;
        // getItems();
      }
    );

    const allPlatformsHolder = platforms();
    const { platformsList } = storeToRefs(allPlatformsHolder);

    const AllBotsHolder = AllBots();
    const { botList, findFreeBots } = storeToRefs(AllBotsHolder);
    const { fetchBotList, setDefaultBotId } = AllBotsHolder;
    const pagination = ref();

    // get servers list
    const serverHolder = Servers();
    // const { fetchServersList } = serverHolder;
    const { serverList } = storeToRefs(serverHolder);
    const bots = ref()

    onMounted(async() => {
      // fetchServersList();

      await ApiService.query("bot/all", {
        params: {
          with_platforms: true,
        with_user: true,
        expiration_type : '',
      },
    })
      .then(({ data }) => {
        bots.value = data.data.bots;
      })
    .catch(({ response }) => {
       console.log(response);
       
      })
     
    });

    const handleSizeChange = (val: number) => {
      return;
    };
    const handleCurrentChange = (val: number) => {
      return;
    };

    const deleteBot = (id) => {
      if (!confirm(translate("Are you sure to delete the subscription?")))
        return;

      ApiService.post(`bot/${id}/delete`, {})
        .then(() => {
          // getItems();
          fetchBotList(expiration_type.value);
        })
        .catch(() => {
          ElNotification({
            title: translate("error"),
            message: translate("Error in deleting subscription"),
            type: "error",
          });
        });
    };

    const disablePOButton = ref(false);
    const powerOff = async (botId?) => {
      if (!confirm(translate("Are you sure the subscription is disconnected?")))
        return;
      disablePOButton.value = true;
      Swal.fire({
        text: translate(
          "The request to disconnect your connected Bot has been registered This operation may take several minutes please be patient"
        ),
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: translate("ok"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-info",
        },
      });

      ApiService.post(`bot/${botId}/power-off`, [])
        .then(() => {
          // getItems();
          fetchBotList(expiration_type.value);
        })
        .catch(() => {
          ElNotification({
            title: translate("error"),
            message: translate("Error in disconnecting!"),
            type: "error",
          });
        });
    };

    // const connectToSocket = () => {
    //   if (JwtService.getUserId()) {
    //     window.Echo.private("user." + JwtService.getUserId()).listen(
    //       ".bot_deactivated ",
    //       () => {
    //         fetchBotList(expiration_type.value);
    //       }
    //     );
    //   }
    // };


     // pulling
    // const pulling_data = ref();
    // const pulling = () => {
    //   if (
    //     route.name === "bot-connections" 
    //   ) {
    //     console.log("bot list pulling");

    //     ApiService.get("/whats-up")
    //       .then((data) => {
    //         console.log(data.data.data);
    //         let res = data.data.data.operations;

    //         for (let i = 0; i < res.length; i++) {
    //           if (res[i].type === "bot_deactivated") {
    //             fetchBotList(expiration_type.value);
    //           }
    //         }
    //       })
    //       .catch(({ response }) => {
    //         console.log(response);
    //       });
    //   }
    // };


    const changeStatus = (botId, newStatus) => {
      for (let i = 0; i < botList.value.length; i++) {
        let bot = botList.value[i];
        if (bot.bot_id != botId) continue;
        botList.value[i].status = newStatus;
      }
    };

    const selectedBot = ref();

    const checkNotActivePackagePlatform = (packagePlatforms, platforms) => {
      const connectedPlatforms =
        platforms !== null && platforms.length > 0
          ? platforms.map((pl) => {
              return pl.platform;
            })
          : [];

      if (connectedPlatforms !== null && packagePlatforms.length > 0)
        return packagePlatforms.filter((x) => !connectedPlatforms.includes(x));
      return connectedPlatforms;
    };

    onMounted(() => {

      // pulling_data.value = localStorage.getItem("user_info");
      // if (JSON.parse(pulling_data.value).polling_mode === true) {
      //   setInterval(() => {
      //     pulling();
      //   }, 8000);
      // } else {
        // connectToSocket();
      // }
        // connectToSocket();



      // setTimeout(getItems, Math.floor(Math.random() * (3 - 1 + 1) + 1) * 1000);
      setCurrentPageBreadcrumbs(translate("My subscriptions"), []);
    });

    watch(botList, () => {
      // if (botList.value.length > 0) {
      loading.value = false;
      // }
    });

    return {
      handleCurrentChange,
      handleSizeChange,
      changeStatus,
      deleteBot,
      powerOff,
      statusColor,
      translate,
      checkPackagePlatform,

      serverHolder,
      serverList,
bots,
      botId,
      loading,
      pagination,
      status,
      route,
      forUser,
      disablePOButton,
      selectedBot,

      platformsList,
      botList,
      fetchBotList,
      checkNotActivePackagePlatform,
      setDefaultBotId,
      dialogVisible,
      userData,
      findFreeBots,

      expiration_type,
    };
  },
});
