
import { defineComponent, onUpdated, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";

import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "edit-connection-modal",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    bot_id: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    platform: {
      type: String,
      default: "",
    },
  },
  emit: ["refreshBotList"],
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const store = useStore();
    const editBotModal = ref<HTMLElement | null>(null);

    const loading = ref(true);

    const avatar = ref();
    const submitButton1 = ref<HTMLElement | null>(null);
    const imgUrl = ref("/media/avatars/blank.png" as any);
    const name = ref();
    const OnChangeAvatar = () => {
      let reader = new FileReader();
      if (avatar.value.files[0]) {
        reader.onload = (e) => {
          imgUrl.value = e.target?.result;
        };
        reader.readAsDataURL(avatar.value.files[0]);
      }
    };

    const AllBotsHolder = AllBots();
    const { findBot } = storeToRefs(AllBotsHolder);
    const { fetchBotList } = AllBotsHolder;

    const submit = () => {
      submitButton1.value?.setAttribute("data-kt-indicator", "on");
      const formData = new FormData();

      if (avatar.value.files[0] !== undefined) {
        formData.append("avatar", avatar.value.files[0]);
      }
      formData.append("name", name.value);

      // `bot/${props.bot_id}/${props.platform}/change-avatar`
      ApiService.post(`bot/${props.bot_id}/edit`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          hideModal(editBotModal.value);
          fetchBotList();
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        });

      setTimeout(() => {
        submitButton1.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    onUpdated(() => {
      name.value = findBot.value?.name;
    });

    return {
      submitButton1,
      loading,
      OnChangeAvatar,
      imgUrl,
      avatar,
      submit,
      editBotModal,
      translate,
      name,
    };
  },
});
